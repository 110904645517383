body {
  background-color: #242f3e;
  color: #fff;
  min-height: inherit;
}

.main-logo {
  width: auto;
  height: 48px;
  display: block;
  margin: 0 auto;
}

.loader-fixed {
  position: fixed;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
  width: 5rem;
  height: 5rem;
  z-index: 999999;
}

.map-footer {
  transform: translateY(200px);
  transition: transform 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.map-footer.show {
  transform: translateY(0px);
}

.nav-shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.amplify-autocomplete .amplify-field-group__inner-start .amplify-icon svg path {
  fill: #fff;
}

.pin-buttons button {
  border-radius: 50%;
  padding: 8px;
  width: 42px;
  background-color: #242f3e;
}

.pin-buttons {
  transform: scale(0);
  animation: zoomIn 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

.chat-list > div {
  border-radius: 4px;
  padding: 0.5rem;
  border-left: 4px solid;
  background-color: rgba(0, 0, 0, 0.15);
}

.chat-list::-webkit-scrollbar {
  display: none;
}

.chat-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* animations */
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
